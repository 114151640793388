import React, { useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  SectionLineDescription,
  Container,
  ExploreMore,
} from "../components/Section";
import PrimaryButton from "../components/Button/PrimaryButton";
import MapBoxMap from "../components/MapBox";
import ProductCardGridView from "../components/ProductCardGridView";

const HeroBanner = styled.section`
  position: relative;
  margin-top: 64px;
  min-height: 220px;
  @media (min-width: 768px) {
    min-height: 280px;
  }
  @media (min-width: 992px) {
    min-height: 400px;
  }
  @media (min-width: 1200px) {
    margin-top: 100px;
    min-height: 500px;
  }
  @media (min-width: 1600px) {
    min-height: 600px;
  }
`;

const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 40px -10px 0px;
  @media (min-width: 992px) {
    margin: 60px -15px 0px;
  }
`;
const ProductGridItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
  @media (min-width: 1200px) {
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
  }
`;

const ProjectsPage = ({ location, data }) => {
  const [showLimit, setShowLimit] = useState(12);
  const allProducts = data.allContentfulProduct.edges;
  return (
    <Layout location={location}>
      <Seo
        title="Know About Our Metal Buildings Projects"
        description="Explore our locations and recently installed buildings. Each of our metal buildings is designed with precision and care. Experience versatility and quality in every design. Browse now!"
      />
      <HeroBanner>
        <MapBoxMap data={allProducts} limit={showLimit} />
      </HeroBanner>

      <Section
        ept="60px"
        epb="60px"
        xpt="40px"
        xpb="40px"
        pt="30px"
        pb="30px"
        bgColor="#fff"
        className="section-desc"
      >
        <SectionLineDescription mb="0" textAlign="center">
          <Container>
            <h1>Projects</h1>
            <p>
              Take a look at our wide range of metal buildings and choose the
              one that suits your needs the best. Our skilled professionals have
              designed these structures with their expertise. There's something
              for everyone in our different building sizes and styles, so don't
              miss out on the opportunity to find the ideal building for you. 
            </p>
            <ProductGrid>
              {allProducts.map((item, i) => {
                if (i < showLimit) {
                  return (
                    <ProductGridItem key={i}>
                      <ProductCardGridView product={item.node} />
                    </ProductGridItem>
                  );
                } else {
                  return true;
                }
              })}
            </ProductGrid>
            {allProducts.length > showLimit && (
              <ExploreMore
                mt="30px"
                onClick={() => setShowLimit(showLimit + 6)}
              >
                <button type="button" aria-label="button">
                  <PrimaryButton text="LOAD MORE" />
                </button>
              </ExploreMore>
            )}
          </Container>
        </SectionLineDescription>
      </Section>
    </Layout>
  );
};
export default ProjectsPage;

export const pageQuery = graphql`
  query ProjectsPagePageQuery {
    allContentfulProduct(sort: { fields: skuNumber, order: ASC }) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
